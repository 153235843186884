import * as actionTypes from './actionTypes';
import * as rd2ApiManager from '../lib/rd2ApiManager';

export function setActiveCareOrderDetails(careOrderInfo) {
	return function (dispatch) {
		dispatch(setActiveCareOrderDetailsAction(careOrderInfo));
		return Promise.resolve();
	};
}

export function setActiveCareOrderDetailsAction(careOrderInfo) {
	return {
		type: actionTypes.SET_ACTIVE_CAREORDERVISIT_DETAILS,
		data: {
			careOrderWorkflowDetails: {
				careOrderVisitIdentifier: careOrderInfo.referenceId,
				externalReferralOrderId: careOrderInfo.externalReferralOrderId,
				expirationDate: careOrderInfo.expirationDate,
				minVisitDate: careOrderInfo.minVisitDate,
				maxVisitDate: careOrderInfo.maxVisitDate,
				approximateVisitDate: careOrderInfo.approximateVisitDate,
				patientReferenceId: careOrderInfo.patientReferenceId,
				notes: careOrderInfo.notes,
				statusCode: careOrderInfo.statusCode,
				statusDisplayName: careOrderInfo.statusDisplayName,
				createdAt: careOrderInfo.createdAt,
				updatedAt: careOrderInfo.updatedAt,
				fromEMR: careOrderInfo.fromEMR,
				careOrderType: careOrderInfo.careOrderType,
				orderDate: careOrderInfo.orderDate,
				productInstanceConsumerName: careOrderInfo.productInstanceConsumerName,
				lockoutEndAt: careOrderInfo.lockoutEndAt,
				lastNotificationSentAt: careOrderInfo.lastNotificationSentAt,
				subgroupName: careOrderInfo.subgroupName,
				errorMessage: careOrderInfo.errorMessage,
				appointments: [{
					appointmentTypeId: careOrderInfo.appointmentTypeId,
					appointmentTypeName: careOrderInfo.appointmentTypeName,
					appointmentTypeExternalCode: careOrderInfo.appointmentTypeExternalCode,
					externalReferralOrderId: careOrderInfo.externalReferralOrderId,
					reasonForVisit: careOrderInfo.reasonForVisit,
					providerFirstName: careOrderInfo.providerFirstName,
					providerLastName: careOrderInfo.providerLastName,
					serviceId: careOrderInfo.serviceId,
					serviceTypeId: careOrderInfo.serviceTypeId,
					serviceTypeName: careOrderInfo.serviceTypeName,
					serviceDisplayName: careOrderInfo.serviceDisplayName,
					referringProviderName: careOrderInfo.referringProviderName,
					referringServiceDisplayName: careOrderInfo.referringServiceDisplayName,
					referringSiteName: careOrderInfo.referringSiteName,
					siteId: careOrderInfo.siteId,
					siteName: careOrderInfo.siteName,
					siteAddress: careOrderInfo.siteAddress,
					specialtyId: careOrderInfo.specialtyId,
					specialtyName: careOrderInfo.specialtyName,
					careOrderCustomField: careOrderInfo.customFields,
				}],
			}
		},
	};
}

export function closeCareOrder(closeCareOrderRequestInfo) {
	return {
		type: actionTypes.CLOSE_CARE_ORDER,
		payload: {
			request: rd2ApiManager.createRequest_CloseCareOrder(closeCareOrderRequestInfo),
		},
	};
}
