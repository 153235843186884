import React from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import DropdownList from '../../../components/input/dropdownList';
import '../css/availabilityResultHeader.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    applyFiltersThunk,
    searchNextScanRangeWithReSortThunk,
    selectAppliedFilters,
    selectAppointmentTypeFilterOptions,
    selectAppointmentTypeModalityFilterOptions,
    selectAvailabilitySearchFieldConfig,
    selectFilteredCalendarCount,
    selectFirstShownResultNum,
    selectIsSearchingAnything,
    selectLastShownResultNum,
    selectScanDayCount,
    selectShowReturnToQuestionsButton,
    selectShowScanNextRangeButton,
    selectSiteFilterOptions,
    selectSortOrderFilterOptions,
} from '../availabilitySlice';
import { decisionSupport as routeToDecisionSupport } from '../../../routes';
import { ASC } from '../../../constants/availabilitySearchFields';

export default function ResultsHeader(props) {
    const dispatch = useDispatch();

    const appliedFilters = useSelector(selectAppliedFilters);
    const appointmentTypes = useSelector(selectAppointmentTypeFilterOptions);
    const appointmentTypeModalities = useSelector(selectAppointmentTypeModalityFilterOptions);
    const sites = useSelector(selectSiteFilterOptions);
    const sortOrders = useSelector(selectSortOrderFilterOptions);
    const firstShownResultNum = useSelector(selectFirstShownResultNum);
    const lastShownResultNum = useSelector(selectLastShownResultNum);
    const numResultCalendars = useSelector(selectFilteredCalendarCount);

    const showReturnToQuestionsButton = useSelector(selectShowReturnToQuestionsButton);

    const {
        sortOrder,
        appointmentTypeFilter,
        appointmentModalityFilter,
        siteFilter
    } = useSelector(selectAvailabilitySearchFieldConfig);

    const showScanNextRangeButton = useSelector(selectShowScanNextRangeButton);
    const numberOfDaysToSearch = useSelector(selectScanDayCount);
    const isSearching = useSelector(selectIsSearchingAnything);

    const handleGoBackToGuidedResponse = () => {
        dispatch(routeToDecisionSupport(true))
    };

    return (
        <>
            <Row className="availability-result-header-row">
                <Col lg="3">
                    <h5 className="availability-result-header">Provider Results</h5>
                    <h5 className="availability-result-subheader">
                        Showing {firstShownResultNum} - {lastShownResultNum} of {numResultCalendars} provider
                        {numResultCalendars > 1 ? 's' : ''} found
                    </h5>
                    {showReturnToQuestionsButton && (
                        <Button color="primary" className="mt-2" onClick={handleGoBackToGuidedResponse}>
                            Return to Questions
                        </Button>
                    )}
                </Col>
                <Col className="sort-and-filter-container">
                    <Container className="d-flex justify-content-end">
                        {sortOrder && sortOrder.isVisible && (
                            <React.Fragment>
                                <Col lg="3" className="sort-filters-col">
                                    <span className="availability-result-header ">Sorted By</span>
                                    <DropdownList
                                        name={'sortOrder'}
                                        className="postSearchFilters"
                                        id="sortOrderResults"
                                        feedback="Please select a sort order."
                                        isClearable={false}
                                        maxheight={250}
                                        placeholder="Select a sort order"
                                        list={sortOrders}
                                        listkey={'label'}
                                        listvalue={'id'}
                                        onChange={(name, e) => dispatch(applyFiltersThunk(
                                            { ...appliedFilters, sortOrder: e?.value }
                                        ))}
                                        value={appliedFilters.sortOrder}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                        {appointmentTypeFilter && appointmentTypeFilter.isVisible && (
                            <React.Fragment>
                                <Col lg="3" className="sort-filters-col">
                                    <span className="availability-result-header">
                                        {appointmentTypeFilter ? `${appointmentTypeFilter.fieldLabel}` : 'Appointment Type'}
                                    </span>
                                    <DropdownList
                                        name={ASC.appointmentType}
                                        className="postSearchFilters"
                                        id="appointmentTypeFilterResults"
                                        isDisabled={!appointmentTypeFilter.isEditable}
                                        maxheight={250}
                                        list={appointmentTypes}
                                        listkey={'label'}
                                        listvalue={'id'}
                                        onChange={(name, e) => dispatch(applyFiltersThunk(
                                            { ...appliedFilters, appointmentTypeIds: e?.value ? [e.value] : [] }
                                        ))}
                                        value={appliedFilters.appointmentTypeIds.length ? appliedFilters.appointmentTypeIds[0] : ''}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                        {appointmentModalityFilter && appointmentModalityFilter.isVisible && (
                            <React.Fragment>
                                <Col lg="3" className="sort-filters-col">
                                    <span className="availability-result-header">
                                        {appointmentModalityFilter ? `${appointmentModalityFilter.fieldLabel}` : 'Appointment Modality'}
                                    </span>
                                    <DropdownList
                                        name={ASC.appointmentModality}
                                        className="postSearchFilters"
                                        id="appointmentModalityFilterResults"
                                        isDisabled={!appointmentModalityFilter.isEditable}
                                        maxheight={250}
                                        list={appointmentTypeModalities}
                                        listkey={'label'}
                                        listvalue={'id'}
                                        onChange={(name, e) => dispatch(applyFiltersThunk(
                                            { ...appliedFilters, appointmentTypeModalityIds: e?.value ? [e.value] : [] }
                                        ))}
                                        value={appliedFilters.appointmentTypeModalityIds.length ? appliedFilters.appointmentTypeModalityIds[0] : ''}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                        {siteFilter && siteFilter.isVisible && (
                            <React.Fragment>
                                <Col lg="3" className="sort-filters-col">
                                    <span className="availability-result-header">{siteFilter ? `${siteFilter.fieldLabel}` : 'Site'}</span>
                                    <DropdownList
                                        name={ASC.siteFilter}
                                        className="postSearchFilters"
                                        id="siteFilterResults"
                                        isDisabled={!siteFilter.isEditable}
                                        maxheight={250}
                                        list={sites}
                                        listkey={'label'}
                                        listvalue={'id'}
                                        onChange={(name, e) => dispatch(applyFiltersThunk(
                                            { ...appliedFilters, sites: e?.value ? [e.value] : [] }
                                        ))}
                                        value={appliedFilters.sites.length ? appliedFilters.sites[0] : ''}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Container>
                </Col>
            </Row>
            {showScanNextRangeButton && (
                <Row>
                    <Col>
                        <div className="d-flex justify-content-end align-items-center full-width">
                            <Button
                                color="secondary"
                                className="mt-2"
                                onClick={() => dispatch(searchNextScanRangeWithReSortThunk())}
                                disabled={isSearching}
                            >
                                Search Next {numberOfDaysToSearch} Days
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
}