/**
 * @format
 */

import { push } from 'connected-react-router';
import moment from 'moment';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import AppointmentCancelView from './views/appointment/appointmentCancelView';
import AppointmentDetailsView from './views/appointment/appointmentDetailsView';
import CancelledAppointmentDetailsView from './views/appointment/cancelledAppointmentDetailsView';
import AppointmentEditView from './views/appointment/appointmentEditView';
import AppointmentRescheduleView from './views/appointment/appointmentRescheduleView';
import AvailabilitySearchView from './features/searchAsync/availabilitySearchView';
import NotFound from './components/NotFound';
import InstanceUnavailable from './components/common/instanceUnavailable';
import PermissionWarning from './components/common/permissionWarning';
import LandingPageSelector from './components/landingPageSelector';
import PrivateRoute from './components/privateRoute';
import PublicRoute from './components/publicRoute';
import mhd2Permissions from './constants/permissions';
import IdlePopupContainer from './utilities/idleTimer/idleTimerContainter';
import BookAppointmentView from './views/bookAppointment/bookAppointmentView';
import DecisionSupportView from './views/decisionSupport/decisionSupportView';
import CreateOrderView from './views/orderManagement/createOrderView';
import SearchOrdersView from './views/orderManagement/searchOrdersView';
import PatientAddView from './views/patient/patientAddView';
import PatientDetailsView from './features/patient/patientDetailsView';
import PatientEditView from './views/patient/patientEditView';
import PatientSearchView from './views/patient/patientSearchView';
import SingleSignOnRedirector from './views/redirectors/singleSignOnRedirector';
import StartCreateCareOrderRedirector from './views/redirectors/startCreateCareOrderRedirector';
import PatientRedirector from './views/redirectors/patientRedirector';
import AppointmentRedirector from './views/redirectors/appointmentRedirector';
import RequestAppointmentDetailsView from './views/requestAppointment/requestAppointmentDetailsView';
import RequestAppointmentView from './views/requestAppointment/requestAppointmentView';

import Login from './features/user/loginView';
import Logout from './features/user/logoutView';
import SsoAccessDenied from './features/user/ssoAccessDeniedView';
import FirstLoginView from './features/user/firstLoginView';
import ForgotPasswordView from './features/user/forgotPasswordView';
import ResetPasswordView from './features/user/resetPasswordView';
import UserProfileView from './features/user/userProfileView';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

/* * * WARNING - All changes to routes must update the Rd2RouterSwitch, URL Builder and/or Action method * * */

/* Rd2RouteSwitch - builds the react router switch component for RD2 routes */
export function Rd2RouteSwitch(props) {
	const { instance, location } = props;

	return (
		<React.Fragment>
			{instance && instance.isInstanceUnavailable && location.pathname !== '/logout' ? (
				<InstanceUnavailable message={instance.instanceUnavailableText} />
			) : (
				<>
					{location.pathname !== '/login' && <IdlePopupContainer />}
					<Switch>
						<PublicRoute path="/login" component={Login} />
						<PublicRoute path="/logout" component={Logout} />
						<PublicRoute path="/forgotpassword" component={ForgotPasswordView} />
						<PublicRoute path="/resetpassword" component={ResetPasswordView} />
						<PublicRoute path="/accessdenied" component={PermissionWarning} />
						<PublicRoute path="/external/login/:token" component={SingleSignOnRedirector} />
						<PublicRoute path="/external/accessdenied" component={SsoAccessDenied} />
						<PrivateRoute exact path="/" component={LandingPageSelector} />
						<PrivateRoute
							path="/appointment/:referenceId/cancel"
							component={AppointmentCancelView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/appointment/:referenceId/details"
							component={AppointmentDetailsView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/cancelledAppointment/:referenceId/details"
							component={CancelledAppointmentDetailsView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/appointment/:referenceId/edit"
							component={AppointmentEditView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/appointment/:referenceId/reschedule"
							component={AppointmentRescheduleView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/appointment/request/:requestId/details"
							component={RequestAppointmentDetailsView}
							requiredPermissions={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/availability/search"
							component={AvailabilitySearchView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/decisionSupport"
							component={DecisionSupportView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/orders/create"
							component={CreateOrderView}
							requiredPermission={mhd2Permissions.orderManagement}
						/>
						<PrivateRoute
							path="/orders/search"
							component={SearchOrdersView}
							requiredPermission={mhd2Permissions.orderManagement}
						/>
						<PrivateRoute
							path="/patient/:referenceId/book/:availabilityId"
							component={BookAppointmentView}
							requiredPermission={mhd2Permissions.appointmentManagement}
						/>
						<PrivateRoute
							path="/patient/search"
							component={PatientSearchView}
							requiredPermission={mhd2Permissions.patientManagement}
						/>
						<PrivateRoute
							path="/patient/create"
							component={PatientAddView}
							requiredPermission={mhd2Permissions.patientManagement}
						/>
						<PrivateRoute
							path="/patient/:referenceId/details"
							component={PatientDetailsView}
							requiredPermission={mhd2Permissions.patientManagement}
						/>
						<PrivateRoute
							path="/patient/:referenceId/edit"
							component={PatientEditView}
							requiredPermission={mhd2Permissions.patientManagement}
						/>
						<PrivateRoute path="/provider/:serviceSiteId/appointment/request" component={RequestAppointmentView} />
						<PrivateRoute path="/user/profile" component={UserProfileView} />
						<PrivateRoute path="/user/firstlogin" component={FirstLoginView} />
						<PrivateRoute path="/start/order/create" component={StartCreateCareOrderRedirector} />
						<PrivateRoute path="/start/patient/:refValue" component={PatientRedirector} />
						<PrivateRoute path="/start/appointment/:refValue" component={AppointmentRedirector} />
						<Route component={NotFound} />
					</Switch>
				</>
			)}
		</React.Fragment>
	);
}

export function menuItems() {
	let menuItems = [
		{ name: 'Search Patient', requiredPermission: mhd2Permissions.patientManagement, action: patientSearchUrl() },
		{
			name: 'Create Order',
			requiredPermission: mhd2Permissions.orderManagement,
			action: createCareOrderUrl(),
			requiresActivePatient: 'true',
		},
		{ name: 'Search Orders', requiredPermission: mhd2Permissions.orderManagement, action: searchOrdersUrl() },
	];
	return menuItems;
}

/* Route Methods */
export function loginUrl() {
	return '/login'
}

export function login() {
	return push(loginUrl());
}

export function createCareOrderUrl() {
	return '/orders/create';
}

export function createCareOrder() {
	return push(createCareOrderUrl());
}

export function forgotPasswordUrl() {
	return '/forgotpassword';
}

export function forgotPassword() {
	return push(forgotPasswordUrl());
}

export function patientAddUrl(firstName = '', lastName = '', dateOfBirth, memberId = '') {
	dateOfBirth = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : '';
	return `/patient/create?firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&memberId=${memberId}`;
}

export function patientAdd(firstName = '', lastName = '', dateOfBirth, memberId = '') {
	return push(patientAddUrl(firstName, lastName, dateOfBirth, memberId));
}

export function patientSearchUrl() {
	return '/patient/search';
}

export function patientSearch() {
	return push(patientSearchUrl());
}

export function patientDetailsUrl(referenceId) {
	return `/patient/${referenceId}/details`;
}

export function patientDetails(referenceId) {
	return push(patientDetailsUrl(referenceId));
}

export function patientEditUrl(referenceId) {
	return `/patient/${referenceId}/edit`;
}

export function patientEdit(referenceId) {
	return push(patientEditUrl(referenceId));
}

export function availabilitySearchUrl() {
	return '/availability/search';
}

export function availabilitySearch() {
	return push(availabilitySearchUrl());
}

export function appointmentDetailsUrl(referenceId) {
	return `/appointment/${referenceId}/details`;
}

export function cancelledAppointmentDetailsUrl(referenceId) {
	return `/cancelledAppointment/${referenceId}/details`;
}

export function appointmentEditUrl(referenceId) {
	return `/appointment/${referenceId}/edit`;
}

export function appointmentEdit(referenceId) {
	return push(appointmentEditUrl(referenceId));
}

export function rescheduleAppointmentUrl(referenceId) {
	return `/appointment/${referenceId}/reschedule`;
}

export function cancelAppointmentUrl(referenceId) {
	return `/appointment/${referenceId}/cancel`;
}

export function requestAppointmentUrl(serviceSiteId) {
	return `/provider/${serviceSiteId}/appointment/request`;
}

export function requestAppointmentDetailsUrl(requestId) {
	return `/appointment/request/${requestId}/details`;
}

export function appointmentDetails(referenceId) {
	return push(appointmentDetailsUrl(referenceId));
}

export function cancelledAppointmentDetails(referenceId) {
	return push(cancelledAppointmentDetailsUrl(referenceId));
}

export function rescheduleAppointment(referenceId) {
	return push(rescheduleAppointmentUrl(referenceId));
}

export function cancelAppointment(referenceId) {
	return push(cancelAppointmentUrl(referenceId));
}

export function startBookingProcessUrl(useDecisionSupport) {
	return useDecisionSupport ? decisionSupportUrl() : availabilitySearchUrl();
}

export function startBookingProcess(useDecisionSupport) {
	return push(startBookingProcessUrl(useDecisionSupport));
}

export function decisionSupportUrl() {
	return '/decisionSupport';
}

export function decisionSupport() {
	return push({
		pathname: decisionSupportUrl(),
	});
}

export function logoutUrl() {
	return '/logout';
}

export function logoutUser() {
	return push(logoutUrl());
}

export function createOrderUrl() {
	return '/orders/create';
}

export function createOrder() {
	return push(createOrderUrl());
}

export function searchOrdersUrl() {
	return '/orders/search';
}

export function bookAppointmentUrl(patientReferenceId, timeSlotId) {
	return `/patient/${patientReferenceId}/book/${timeSlotId}`;
}

export function searchOrders() {
	return push(searchOrdersUrl());
}

export function requestAppointment(serviceSiteId) {
	return push(requestAppointmentUrl(serviceSiteId));
}

export function requestAppointmentDetails(requestId) {
	return push(requestAppointmentDetailsUrl(requestId));
}

export function routeToBooking(patientReferenceId, timeSlotId) {
	return push(bookAppointmentUrl(patientReferenceId, timeSlotId));
}
